import React, { useEffect, useRef, useState } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import styles from "./chatbot.module.css";
import BotCal from "../custom/BotCal.jsx";
import { sendChatIcon } from "../../constants/images.js";
import RangeSelector from "../custom/inputFields/RangeSelector.jsx";
import SingleRadio from "../custom/inputFields/RadioInput.jsx";
import MultipleCheckbox from "../custom/inputFields/MulticheckInput.jsx";
import DropdownMinMax from "../custom/inputFields/DropDownMinMax.jsx";
import DropdownSingle from "../custom/inputFields/DropDown.jsx";
import TextMinMax from "../custom/inputFields/TextMinMax.jsx";
import PropertyValuation from "../custom/valuation/PropertyValuation.jsx";
import PropertyUnavailable from "../custom/valuation/PropertyUnavailable.jsx";
import SelectProperty from "../custom/valuation/SelectProperty.jsx";

const ChatbotMessages = ({
  chatId,
  messages,
  chatLoading,
  handleSendDailogApi,
  createChat,
  onChatMsgSubmit,
  createType,
  chatBotAdminData,
  stopSendloading,
  chatClose,
  onClose,
  setCaptureFieldError,
  setCaptureFieldValue,
  handleRating,
  adminid,
  userImage,
  name,
  apiBaseUrl,
  sessionId,
  range,
  maxRange,
  rangeData,
  setRangeData,
}) => {
  const isFormAlreadySubmitted = JSON.parse(localStorage.getItem(chatId));
  const chatContainerRef = useRef(null);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    });

    if (chatContainerRef.current) {
      observer.observe(chatContainerRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={styles.all_chatpart_body} ref={chatContainerRef}>
      {messages?.map((message, index) => {
        const isUserMessage =
          message.sender === isFormAlreadySubmitted?._id ||
          message.sender == null;
        return (
          <>
            <div
              className={
                isUserMessage || message.sender === "you"
                  ? `${styles.sender_box} ${styles.sender_right_side}`
                  : styles.sender_box
              }
            >
              {isUserMessage || message.sender === "you" ? (
                <>
                  You{" "}
                  <span>
                    {isFormAlreadySubmitted?.name?.slice(0, 1) || "y"}
                  </span>
                </>
              ) : (
                <>
                  <img src={userImage || sendChatIcon} alt="" />
                  {message.sender.name || name || "Bot"}
                </>
              )}{" "}
            </div>

            {!message?.propertyList?.status &&
              message?.propertyDetails?.status !== "failed" && (
                <div
                  key={message._id}
                  className={
                    isUserMessage || message.sender === "you"
                      ? `${styles.box} ${styles.right_side}`
                      : styles.box
                  }
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: message?.message
                        ? message?.message?.replace(/\n/g, " <br/> ")
                        : "",
                    }}
                  ></div>
                </div>
              )}
            {message?.field === "dailog_select" && message?.value !== "" && (
              <Stack
                direction="row "
                sx={{ flexWrap: "wrap" }}
                spacing={2}
                className={styles.sender_right_side}
              >
                {message.options?.map((data, index) => {
                  return (
                    <span
                      style={{ margin: "3px 2px" }}
                      className={message.value === data && styles.slectedChip}
                    >
                      <Chip
                        label={data}
                        variant={message.value === data ? "filled" : "outlined"}
                      />
                    </span>
                  );
                })}
              </Stack>
            )}

            {message?.propertyList?.data?.raw_data?.length > 0 && (
              <div>
                <SelectProperty
                  template={""}
                  properties={message?.propertyList?.data?.raw_data}
                  isActive={index === messages?.length - 1}
                  handleSendDailogApi={handleSendDailogApi}
                  chatId={chatId}
                />
              </div>
            )}

            {message?.propertyDetails?.status === "success" &&
              !(
                message?.propertyDetails?.saleValuation?.status ||
                message?.propertyDetails?.letValuation?.status
              ) && (
                <div>
                  <PropertyValuation
                    template={""}
                    propertyDetail={message?.propertyDetails}
                  />
                </div>
              )}
            {(message?.propertyList?.status === "failed" ||
              message?.propertyDetails?.status === "failed" ||
              (message?.propertyDetails?.valuationType === "both" &&
                message?.propertyDetails?.saleValuation?.status === "failed" &&
                message?.propertyDetails?.letValuation?.status ===
                  "failed")) && (
              <div>
                <PropertyUnavailable
                  template={""}
                  propertyDetail={message?.propertyDetails}
                  onClick={(msg) => {
                    if (chatClose) {
                      handleRating(
                        msg,
                        setMsg,
                        onClose,
                        isFormAlreadySubmitted,
                        chatId
                      );
                    } else {
                      if (!createChat) {
                        handleSendDailogApi(msg, chatId);
                      }
                    }
                  }}
                  isActive={index === messages?.length - 1}
                  status={
                    message?.propertyList?.status === "failed" ? true : false
                  }
                />
              </div>
            )}
            {message?.propertyDetails?.saleValuation?.status === "success" && (
              <div>
                <PropertyValuation
                  template={""}
                  propertyDetail={message?.propertyDetails?.saleValuation}
                />
              </div>
            )}
            {message?.propertyDetails?.letValuation?.status === "success" && (
              <div>
                <PropertyValuation
                  template={""}
                  propertyDetail={message?.propertyDetails?.letValuation}
                />
              </div>
            )}
          </>
        );
      })}

      {messages?.[messages.length - 1]?.customFieldTitle?.fieldType ===
        "text-min-max" && (
        <div key={`${styles.box}`}>
          <div>
            <TextMinMax
              name={
                messages?.[messages.length - 1]?.customFieldTitle?.displayField
                  ?.chatbotLabel
              }
              prefix={
                messages?.[messages.length - 1]?.customFieldTitle?.displayField
                  ?.prefix
              }
              postfix={
                messages?.[messages.length - 1]?.customFieldTitle?.displayField
                  ?.postfix
              }
              setCaptureFieldError={setCaptureFieldError}
              setCaptureFieldValue={setCaptureFieldValue}
            />
          </div>
        </div>
      )}
      {messages?.[messages.length - 1]?.customFieldTitle?.fieldType ===
        "single-radio" && (
        <div key={`${styles.box}`}>
          <div>
            <SingleRadio
              name={
                messages?.[messages.length - 1]?.customFieldTitle?.displayField
                  ?.chatbotLabel
              }
              options={
                messages?.[messages.length - 1]?.customFieldTitle?.fieldOptions
              }
              setCaptureFieldError={setCaptureFieldError}
              setCaptureFieldValue={setCaptureFieldValue}
              prefix={
                messages?.[messages.length - 1]?.customFieldTitle?.displayField
                  ?.prefix
              }
              postfix={
                messages?.[messages.length - 1]?.customFieldTitle?.displayField
                  ?.postfix
              }
            />
          </div>
        </div>
      )}
      {messages?.[messages.length - 1]?.customFieldTitle?.fieldType ===
        "multiple-checkbox" && (
        <div key={`${styles.box}`}>
          <div>
            <MultipleCheckbox
              name={
                messages?.[messages.length - 1]?.customFieldTitle?.displayField
                  ?.chatbotLabel
              }
              options={
                messages?.[messages.length - 1]?.customFieldTitle?.fieldOptions
              }
              setCaptureFieldError={setCaptureFieldError}
              setCaptureFieldValue={setCaptureFieldValue}
              prefix={
                messages?.[messages.length - 1]?.customFieldTitle?.displayField
                  ?.prefix
              }
              postfix={
                messages?.[messages.length - 1]?.customFieldTitle?.displayField
                  ?.postfix
              }
            />
          </div>
        </div>
      )}
      {messages?.[messages.length - 1]?.customFieldTitle?.fieldType ===
        "dropdown-single" && (
        <div key={`${styles.box}`}>
          <div>
            <DropdownSingle
              name={
                messages?.[messages.length - 1]?.customFieldTitle?.displayField
                  ?.chatbotLabel
              }
              options={
                messages?.[messages.length - 1]?.customFieldTitle?.fieldOptions
              }
              setCaptureFieldError={setCaptureFieldError}
              setCaptureFieldValue={setCaptureFieldValue}
              prefix={
                messages?.[messages.length - 1]?.customFieldTitle?.displayField
                  ?.prefix
              }
              postfix={
                messages?.[messages.length - 1]?.customFieldTitle?.displayField
                  ?.postfix
              }
            />
          </div>
        </div>
      )}
      {messages?.[messages.length - 1]?.customFieldTitle?.fieldType ===
        "dropdown-min-max" && (
        <div key={`${styles.box}`}>
          <div>
            <DropdownMinMax
              setCaptureFieldError={setCaptureFieldError}
              setCaptureFieldValue={setCaptureFieldValue}
              prefix={
                messages?.[messages.length - 1]?.customFieldTitle?.displayField
                  ?.prefix
              }
              postfix={
                messages?.[messages.length - 1]?.customFieldTitle?.displayField
                  ?.postfix
              }
              name={
                messages?.[messages.length - 1]?.customFieldTitle?.displayField
                  ?.chatbotLabel
              }
              minOptions={messages?.[
                messages.length - 1
              ]?.customFieldTitle?.fieldOptions?.filter((data) =>
                data?.optionType?.includes("min")
              )}
              maxOptions={messages?.[
                messages.length - 1
              ]?.customFieldTitle?.fieldOptions?.filter((data) =>
                data?.optionType?.includes("min")
              )}
            />
          </div>
        </div>
      )}

      {messages?.[messages.length - 1]?.field === "dailog_select" &&
        messages?.[messages.length - 1]?.value === "" && (
          <Stack
            direction="row "
            sx={{ flexWrap: "wrap" }}
            spacing={2}
            className={styles.sender_right_side}
          >
            {messages?.[messages.length - 1].options?.map((data, index) => {
              return (
                <span style={{ margin: "3px 2px" }}>
                  <Chip
                    label={data}
                    variant={
                      messages?.[messages.length - 1].value === data
                        ? ""
                        : "outlined"
                    }
                    onClick={(e) => {
                      if (chatClose) {
                        handleRating(
                          data,
                          setMsg,
                          onClose,
                          isFormAlreadySubmitted
                        );
                      } else {
                        if (!createChat) {
                          handleSendDailogApi(data, chatId);
                        } else {
                          onChatMsgSubmit(
                            e,
                            data,
                            chatId,
                            isFormAlreadySubmitted,
                            setMsg
                          );
                        }
                      }
                    }}
                  />
                </span>
              );
            })}
          </Stack>
        )}

      {(createType === "arrange-callback" ||
        createType === "property-viewing" ||
        createType === "schedule-valuation") && (
        <div className={styles.sender_right_side} style={{ display: "flex" }}>
          <BotCal
            handleSendDailogApi={handleSendDailogApi}
            chatId={chatId}
            chatBotAdminData={chatBotAdminData}
            stopSendloading={stopSendloading}
            messages={messages}
            adminid={adminid}
            apiBaseUrl={apiBaseUrl}
            sessionId={sessionId}
          />
        </div>
      )}

      {range && (
        <RangeSelector
          maxRange={maxRange}
          rangeData={rangeData}
          setRangeData={setRangeData}
        />
      )}

      {chatLoading && (
        <div className={styles.chat_bubble}>
          <div className={styles.typing}>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatbotMessages;
