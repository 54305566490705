import React, { useEffect, useRef, useState } from "react";
import styles from "./chatbot.module.css";
import Popover from "@mui/material/Popover";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import useAddress from "./hooks/useAddress";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { emoji, sendIcon } from "../../constants/images";
import { FIELD_TYPE } from "../../constants/formFields";

const ChatbotMessageSend = ({ messages, chatId, isBotWorking, chatClose, onClose, handleEmojiSelect, onChatMsgSubmit, captureFieldError, captureFieldValue,
  handleRating, handleSendDailogApi, createChat, isAddress, stopSendloading, apiBaseUrl, createType, range, rangeData }) => {

  const [msg, setMsg] = useState("");
  const { onChangeAddress, placesData, showList, setShowList, handleSaveAddresswithpostCode, fullAddress, setFullAddress } = useAddress(apiBaseUrl);

  const isFormAlreadySubmitted = JSON.parse(localStorage.getItem(chatId));
  const textareaRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function to handle click event on emoji icon
  const handleClick = (event) => {
    if (!range && (!FIELD_TYPE?.includes(messages?.[messages.length - 1]?.customFieldTitle?.fieldType))) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => { setAnchorEl(null); };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function handleKeyDown(e) {
    if (
      e.key === "Enter" && isBotWorking && msg &&
      (isAddress ? (isAddress && fullAddress) : true) &&
      (!captureFieldError) &&
      messages?.[messages.length - 1]?.field !== "dailog_select" && !stopSendloading &&
      (createType !== "arrange-callback" && createType !== "property-viewing" && createType !== "schedule-valuation" && createType !== "property-valuation")
    ) {

      if (chatClose) {
        handleRating(msg, setMsg, onClose, isFormAlreadySubmitted);
      } else {

        if (!createChat) {
          handleSendDailogApi(msg, chatId);
          setMsg("");
        } else {
          onChatMsgSubmit(e, msg, chatId, isFormAlreadySubmitted, setMsg);
        }
      }
    }
  }

  useEffect(() => {
    if (range) {
      setMsg(rangeData + " " + range)
      textareaRef.current.focus();
    }
  }, [rangeData, range])



  useEffect(() => {
    if (captureFieldValue) {
      setMsg(captureFieldValue)
    }
  }, [captureFieldValue])

  return (
    <div className={styles.all_chatpart_footer + " d-flex"}>
      <img src={emoji} alt="" onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left", }}
        transformOrigin={{ vertical: "top", horizontal: "right", }}
        sx={{ zIndex: "9999999999" }}
      >
        <Picker
          data={data}
          onEmojiSelect={(emoji) => handleEmojiSelect(emoji, textareaRef, setMsg, handleClose)}
          theme="light"
          emojiSize={18}
          previewPosition="none"
        />
      </Popover>

      {isAddress ? (
        <Autocomplete
          disablePortal
          open={showList && msg?.length > 1}
          id="select-on-focus"
          selectOnFocus
          disableClearable
          inputValue={msg}
          filterOptions={(options, state) => options}
          onChange={(event, newValue) => {
            handleSaveAddresswithpostCode(newValue, setMsg)
            setShowList(false);
          }}
          options={placesData}
          sx={{ width: 300, color: "#464749", p: 0, m: 0 }}
          getOptionLabel={(option) => option?.description}
          getOptionSelected={(option, value) => option?.description === value?.description}
          renderOption={(props, option) => (
            <Box {...props} sx={{ padding: "0", margin: "0" }}>
              <span
                className={styles.placeList}
                style={{
                  padding: "0",
                  margin: "0",
                  width: "100%",
                  borderBottom: "none",
                }}
              >
                {option.description}
              </span>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              className={styles.address_input}
              placeholder="Search address ..."
              autoFocus={true}
              value={msg}
              onChange={(e) => {
                if (!range) {
                  setMsg(e.target.value);
                  if (isAddress && e.target.value.length > 1) {
                    onChangeAddress(e, messages?.slice(-1)[0]?.category);
                  } else {
                    setShowList(false);
                  }
                }
              }}
              onKeyPress={(e) => {
                handleKeyDown(e);
              }}
            />
          )}
        />
      ) : (
        <input
          type="text"
          ref={textareaRef}
          name=""
          id=""
          disabled={range && !msg}
          placeholder={messages?.[messages.length - 1]?.customFieldTitle?.fieldType === "multiple-checkbox" ?
            "Please select options above." :
            messages?.[messages.length - 1]?.customFieldTitle?.fieldType === "dropdown-min-max" ?
              "Please select min & max in option above." :
              messages?.[messages.length - 1]?.customFieldTitle?.fieldType === "text-min-max" ?
                "Please enter min & max in above fields." :
                (messages?.[messages.length - 1]?.customFieldTitle?.fieldType === "single-radio" || messages?.[messages.length - 1]?.customFieldTitle?.fieldType === "dropdown-single") ?
                  "Please select an option above." : "Enter your message.."
          }
          value={msg}
          onChange={(e) => {
            if (!range && (!FIELD_TYPE?.includes(messages?.[messages.length - 1]?.customFieldTitle?.fieldType))) {
              setMsg(e.target.value);
            }
          }}
          onKeyPress={(e) => {
            handleKeyDown(e);
          }}
        />
      )}
      <div
        onClick={(e) => {
          if (
            isBotWorking && msg && !stopSendloading && (isAddress ? (isAddress && fullAddress) : true) &&
            (!captureFieldError) &&
            messages?.[messages.length - 1]?.field !== "dailog_select" &&
            (createType !== "arrange-callback" && createType !== "property-viewing" && createType !== "schedule-valuation" && createType !== "property-valuation")
          ) {
            if (chatClose) {
              handleRating(msg, setMsg, onClose, isFormAlreadySubmitted);
            } else {
              if (!createChat) {
                handleSendDailogApi(msg, chatId);
                setMsg("");
              } else {
                onChatMsgSubmit(e, msg, chatId, isFormAlreadySubmitted, setMsg);
              }
            }
          }
        }}
      >
        <img className={styles.send_icon} src={sendIcon} alt="" />
      </div>
    </div>
  );
};

export default ChatbotMessageSend;
