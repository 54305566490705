// PropertyValuation.js
import React from "react";
import styles from "./PropertyValuation.module.css";
import { capitalizeString } from "../../../utils/validators";

const PropertyValuation = ({ template, propertyDetail }) => {
  return (
    <div className={`${styles.container} ${styles[`container_${template}`]}`}>
      <div className={styles.header}>
        <span style={{ display: "flex" }}>
          <h2 className={styles.title}>Property Insight</h2>
          <button className={styles.confidence}>
            {propertyDetail?.valuationType == "both"
              ? "Combine"
              : capitalizeString(propertyDetail?.valuationType)}
          </button>
        </span>
        {/* {propertyDetail?.valuationType === "sell" ? (
          <h1 className={styles.value}>
            £{propertyDetail?.price}
            <span>{propertyDetail?.result?.unit}</span>
          </h1>
        ) : ( */}
          <h1 className={styles.value}>
            £
            {propertyDetail?.result?.unit === "gbp_per_week"
              ? parseInt(propertyDetail?.result?.estimate) * 4
              : propertyDetail?.result?.estimate}
            <span>
              {propertyDetail?.result?.unit &&
                (propertyDetail?.result?.unit === "gbp_per_week"
                  ? "/ per month"
                  : "/ per month")}
            </span>
          </h1>
        {/* )} */}
      </div>
      {/* {propertyDetail?.valuationType === "sell" &&
        propertyDetail?.price > 0 && (
          <div className={styles.history}>
            <p className={styles.address}>
              {propertyDetail?.address}, {propertyDetail?.postcode}
            </p>
            <div className={styles.historyItem}>
              <p className={styles.details}>
                <span>Property:</span>{" "}
                {capitalizeString(propertyDetail?.type)}{" "}
                <span className={styles.history_seperator}>|</span>{" "}
                <span>Tenure:</span>{" "}
                {capitalizeString(propertyDetail?.tenure)}
              </p>
            </div>
          </div>
        )} */}
    </div>
  );
};

export default PropertyValuation;
