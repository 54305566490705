export const chatImg = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/ChatIcon.svg";
export const liveIcon = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/liveDot.svg";
export const logo = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/propertyjinni-favicon.svg";
export const emoji = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/Vector.svg";
export const sendIcon = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/sendIcon.svg";
export const sendChatIcon ="https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/sendechatIcon.svg";


// Instant valuation

export const historyIcon = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/HistoryIcon.svg";
export const NoData = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/houseIcon.png";

// Elite International

export const EliteShape = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/EliteShape.svg";
export const chatImgElite = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/sendIconElite.svg";
export const EliteAgent = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/EliteAgent.png";