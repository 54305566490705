module.exports.isValidMobileNumber = (number) => {
  // Define a regular expression pattern for a typical mobile number with optional "+"
  const pattern = /^\+?\d{10}(?:\d{2})?$/;
  // Check if the given number matches the pattern
  return pattern.test(number);
};

module.exports.isValidEmail = (email) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
};

module.exports.isValidRating = (msg) => {
  // Convert the message to lowercase before comparing
  const lowerCaseMsg = msg.toLowerCase();

  // Check if the message is one of the valid ratings
  return (
    lowerCaseMsg === "good" ||
    lowerCaseMsg === "bad" ||
    lowerCaseMsg === "average"
  );
};

// Wrap setTimeout in a Promise
module.exports.delayedExecution = () =>
  new Promise((resolve) => {
    const timeoutId = setTimeout(() => {
      resolve();
      clearTimeout(timeoutId); // Clear the timeout after execution
    }, 1000);
  });

  module.exports.capitalizeFirstLetter = (string) => {
    return string?.toLowerCase().replace(/\b\w/g, function (char) {
      return char?.toUpperCase();
    });
  }

  module.exports.capitalizeString= (string) => {
    return string?.replace(/_/g, " ")?.replace(/\b\w/g, c => c?.toUpperCase())
  }
