// SelectProperty.js
import React, { useState, useEffect } from 'react';
import styles from './SelectProperty.module.css';

const SelectProperty = ({ template, properties, isActive, handleSendDailogApi, chatId }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const getHoverButtonColor = (template) => {
    const colors = {
      btc: "#3c6e95",
      talbies: "#B09981",
      charlessinclair: "#c7b354",
      denhan: "#b82142",
      daniels: "#090B0C",
      magicbrick: "#545659",
      lundiniom: "#545659",
      caridon: "#022b63",
    };
    return colors[template] || "#933eff";
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      // Move down in the list
      setSelectedIndex((prevIndex) => (prevIndex + 1) % properties.length);
    } else if (event.key === "ArrowUp") {
      // Move up in the list
      setSelectedIndex((prevIndex) =>
        prevIndex === 0 ? properties.length - 1 : prevIndex - 1
      );
    } else if (event.key === "Enter") {
      // Select the current property
      handleSendDailogApi(properties[selectedIndex]?.address, chatId);
    }
  };

  // Add event listener for keydown
  useEffect(() => {
    if (isActive) {
      window.addEventListener("keydown", handleKeyDown);
      return () => window.removeEventListener("keydown", handleKeyDown);
    }
  }, [isActive, selectedIndex, properties]);

  const selectedBackgroundColor = getHoverButtonColor(template);

  return (
    <div className={`${styles.container} ${styles[`container_${template}`]}`}>
      <p className={styles.title}>Select a property to continue</p>
      <div className={styles.list}>
        {properties.map((property, index) => (
          <div
            key={index}
            className={`${styles.item} ${(index === selectedIndex) && isActive ? styles.selected : ""
              }`}
            style={
              ((index === selectedIndex) && isActive) ? { background: selectedBackgroundColor } : {}
            }
            onClick={() => {
              if (isActive) {
                handleSendDailogApi(property?.address, chatId);
              }
            }}
          >
            {property?.address}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectProperty;
